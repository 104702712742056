import { Injectable } from '@angular/core';
import { CurrentUserPreferenceStore } from '../../core/store/current-user-preference.store';
import { FileDetailsDTO } from '../dto/file.dto';

@Injectable({
  providedIn: 'root',
})
export class FileConversion {
  private lang: string;

  constructor(private currentUserPreferenceStore: CurrentUserPreferenceStore) {
    this.lang = this.currentUserPreferenceStore.languageKey;
  }

  resToFileDetailsDTO(response: any): FileDetailsDTO | null {
    if (!response) {
      return null;
    }
    return {
      id: response.id,
      file_name: response.file_name,
      original_name: response.original_name,
      token: response.token,
      url: response.url,
      downloadUrl: '',
      type: response.type,
      size: response.size,
      language: response[this.lang],
    };
  }

  resToFileDetailsDTOArray(responseArray: any[]): FileDetailsDTO[] {
    if (!responseArray || !Array.isArray(responseArray)) {
      return [];
    }
    return responseArray
      .map((response) => this.resToFileDetailsDTO(response))
      .filter((item) => item !== null) as FileDetailsDTO[];
  }
}
